import {
  amber,
  amberA,
  amberDark,
  amberDarkA,
  blackA,
  blue,
  blueA,
  blueDark,
  blueDarkA,
  red,
  redA,
  redDark,
  redDarkA,
  slate,
  slateA,
  slateDark,
  slateDarkA,
  teal,
  tealA,
  tealDark,
  tealDarkA,
  whiteA,
} from "@radix-ui/colors"

const darkSchemeColors = {
  ...amberDark,
  ...amberDarkA,
  ...redDark,
  ...redDarkA,
  ...slateDark,
  ...slateDarkA,
  ...tealDark,
  ...tealDarkA,
  ...blueDark,
  ...blueDarkA,
}

const lightSchemeColors = {
  ...amber,
  ...amberA,
  ...red,
  ...redA,
  ...slate,
  ...slateA,
  ...teal,
  ...tealA,
  ...blue,
  ...blueA,
}

const accentColor = (accent: string) => ({
  accent1: `$${accent}1`,
  accent2: `$${accent}2`,
  accent3: `$${accent}3`,
  accent4: `$${accent}4`,
  accent5: `$${accent}5`,
  accent6: `$${accent}6`,
  accent7: `$${accent}7`,
  accent8: `$${accent}8`,
  accent9: `$${accent}9`,
  accent10: `$${accent}10`,
  accent11: `$${accent}11`,
  accent12: `$${accent}12`,

  accentA1: `$${accent}A1`,
  accentA2: `$${accent}A2`,
  accentA3: `$${accent}A3`,
  accentA4: `$${accent}A4`,
  accentA5: `$${accent}A5`,
  accentA6: `$${accent}A6`,
  accentA7: `$${accent}A7`,
  accentA8: `$${accent}A8`,
  accentA9: `$${accent}A9`,
  accentA10: `$${accent}A10`,
  accentA11: `$${accent}A11`,
  accentA12: `$${accent}A12`,
})

const grayColor = (gray: string, grayA: string) => ({
  gray1: `$${gray}1`,
  gray2: `$${gray}2`,
  gray3: `$${gray}3`,
  gray4: `$${gray}4`,
  gray5: `$${gray}5`,
  gray6: `$${gray}6`,
  gray7: `$${gray}7`,
  gray8: `$${gray}8`,
  gray9: `$${gray}9`,
  gray10: `$${gray}10`,
  gray11: `$${gray}11`,
  gray12: `$${gray}12`,

  grayA1: `$${grayA}1`,
  grayA2: `$${grayA}2`,
  grayA3: `$${grayA}3`,
  grayA4: `$${grayA}4`,
  grayA5: `$${grayA}5`,
  grayA6: `$${grayA}6`,
  grayA7: `$${grayA}7`,
  grayA8: `$${grayA}8`,
  grayA9: `$${grayA}9`,
  grayA10: `$${grayA}10`,
  grayA11: `$${grayA}11`,
  grayA12: `$${grayA}12`,
})

const semanticAliases = {
  success1: "$teal1",
  success2: "$teal2",
  success3: "$teal3",
  success4: "$teal4",
  success5: "$teal5",
  success6: "$teal6",
  success7: "$teal7",
  success8: "$teal8",
  success9: "$teal9",
  success10: "$teal10",
  success11: "$teal11",
  success12: "$teal12",

  danger1: "$red1",
  danger2: "$red2",
  danger3: "$red3",
  danger4: "$red4",
  danger5: "$red5",
  danger6: "$red6",
  danger7: "$red7",
  danger8: "$red8",
  danger9: "$red9",
  danger10: "$red10",
  danger11: "$red11",
  danger12: "$red12",

  currentBg: "$gray1",

  divider: "$grayA6",
  focus: "$amber9",
  focusRing: "$amberA7",

  scrollbarBg: "transparent",
  scrollbarFg: "$grayA8",
}

// -----------------------------------------------------------------------------
// Global color schemes
// -----------------------------------------------------------------------------

/**
 * Global light scheme – default.
 */
export const colorsLight = {
  // Color-scheme-independent scales
  ...blackA,
  ...whiteA,

  // Color-scheme-dependent scales
  ...lightSchemeColors,
  ...accentColor("red"),
  ...grayColor("slate", "slateA"),
  ...semanticAliases,

  popoverBg: "$gray1",
  popoverBorder: "transparent",
  transparent: "hsl(206 30.0% 98.8% / 0)",
}

/**
 * Global dark scheme – apply by adding `dark-theme` class to the `<body>`
 */
export const colorsDark = {
  ...darkSchemeColors,
  ...accentColor("amber"),
  ...grayColor("slate", "slateA"),
  ...semanticAliases,

  popoverBg: "$gray2",
  popoverBorder: "$divider",
  transparent: "hsl(200 7.0% 8.8% / 0)",
}

// -----------------------------------------------------------------------------
// High contrast schemes
// -----------------------------------------------------------------------------

// These are intended to be applied locally to elements which have saturated colorful (accent) or image background.
//
// - Foreground elements will be turned black/white
// - Gray grades are turned into semi-transparent black/white so they will be tinted by background color.
// - Accent color will be also turned black/white.
//
// Use hiContrastLight for lighter backgrounds and hiContrastDark for darker backgrounds.
// See [Radix colors documentation](https://www.radix-ui.com/docs/colors/palette-composition/composing-a-palette)

export const colorsHiContrastDark = {
  ...darkSchemeColors,
  ...grayColor("whiteA", "whiteA"),
  ...semanticAliases,

  gray1: "black",
  accent9: "white",
  accent10: "$grayA11",
  accent11: "$grayA10",
  accent12: "$grayA9",
  focus: "black",
  focusRing: "$grayA10",
}

export const colorsHiContrastLight = {
  ...lightSchemeColors,
  ...grayColor("blackA", "blackA"),
  ...semanticAliases,

  gray1: "white",
  accent9: "black",
  accent10: "$grayA11",
  accent11: "$grayA10",
  accent12: "$grayA9",
  focus: "black",
  focusRing: "$grayA10",
}
