import { i18n } from "@lingui/core"
import { useLingui } from "@lingui/react"
import { pipe } from "fp-ts/function"

/**
 * Load messages for requested locale and activate it.
 * This function isn't part of the LinguiJS library because there're
 * many ways how to load messages — from REST API, from file, from cache, etc.
 */
export async function loadLocale(locale: string) {
  const { messages } = await import(`../../locales/${locale}/messages.po`)

  i18n.load(locale, messages)
  i18n.activate(locale)
}

// -----------------------------------------------------------------------------
// Hooks
// -----------------------------------------------------------------------------

export type LocalizeFn = (
  locString: Record<string, string>,
  options?: { fallback?: string }
) => string | undefined

export function useI18n() {
  const { i18n } = useLingui()

  const localize: LocalizeFn = (locString, { fallback } = {}) =>
    pipe(locString[i18n.locale], (str) => (!str ? fallback : str))

  return { i18n, localize } as const
}
