import { combine, createDomain } from "effector"
import { persist } from "effector-storage/local"

import { ActualColorScheme, ColorScheme } from "@/domains/color-scheme/model"
import { CurrencyCode } from "@/domains/currencies/model"
import { LengthUnit } from "@/domains/units/model"
import { darkTheme } from "@/stitches"

import { WebsiteSettings, defaultWebsiteSettings } from "./model"

const domain = createDomain("settings")

export const setSettings = domain.createEvent<Partial<WebsiteSettings>>()
export const setCurrency = domain.createEvent<CurrencyCode>()
export const setLengthUnit = domain.createEvent<LengthUnit>()
export const setColorScheme = domain.createEvent<ColorScheme>()

export const systemColorSchemeChanged = domain.createEvent<ActualColorScheme>()

export const $settings = domain
  .createStore<WebsiteSettings>(defaultWebsiteSettings, { name: "$settings" })
  .on(setSettings, (settings, newSettings) => ({
    currency: newSettings.currency ?? settings.currency,
    lengthUnit: newSettings.lengthUnit ?? settings.lengthUnit,
    colorScheme: newSettings.colorScheme ?? settings.colorScheme,
  }))
  .on(setCurrency, (settings, currency) => ({ ...settings, currency }))
  .on(setLengthUnit, (settings, lengthUnit) => ({ ...settings, lengthUnit }))
  .on(setColorScheme, (settings, colorScheme) => ({ ...settings, colorScheme }))

export const $systemColorScheme = domain
  .createStore<ActualColorScheme | null>(null, {
    name: "$systemColorScheme",
  })
  .on(systemColorSchemeChanged, (_, scheme) => scheme)

export const $actualColorScheme = combine(
  $settings,
  $systemColorScheme,
  (settings, systemColorScheme): ActualColorScheme => {
    if (settings.colorScheme === "system") {
      return systemColorScheme ?? "dark"
    }
    return settings.colorScheme
  }
)

$actualColorScheme.watch((scheme) => {
  if (process.browser) {
    document.body.classList.toggle(darkTheme, scheme === "dark")
  }
})

persist({ store: $settings })
