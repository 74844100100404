import { createStitches } from "@stitches/react"
import type * as Stitches from "@stitches/react"

import { media } from "./media"
import {
  colorsHiContrastDark,
  colorsHiContrastLight,
  colorsLight,
} from "./theme/colors"
import { colorsDark } from "./theme/colors"
import { shadowsDark, shadowsLight } from "./theme/shadows"
import { containerSizes } from "./theme/size"
import { space } from "./theme/space"
import {
  fontSizes,
  fontWeights,
  fonts,
  letterSpacings,
  lineHeights,
} from "./theme/typography"
import { zIndices } from "./theme/z-indices"
import { utils } from "./utils"

export const {
  config,
  createTheme,
  css,
  getCssText,
  globalCss,
  keyframes,
  styled,
  theme,
} = createStitches({
  theme: {
    colors: colorsLight,
    space,
    fonts,
    fontSizes,
    fontWeights,
    letterSpacings,
    lineHeights,
    sizes: containerSizes,
    shadows: shadowsLight,
    zIndices,
    radii: {
      none: 0,
      full: "9999px",
    },
  },
  utils,
  media,
})

// For explanation of different color themes, see `./theme/colors.ts`
// We also assign shadows since those also use theme's colors.

export const darkTheme = createTheme("dark-theme", {
  colors: colorsDark,
  shadows: shadowsDark,
})

export const hiContrastLightTheme = createTheme("hi-contrast-light-theme", {
  colors: colorsHiContrastLight,
  shadows: shadowsDark,
})

export const hiContrastDarkTheme = createTheme("hi-contrast-dark-theme", {
  colors: colorsHiContrastDark,
  shadows: shadowsDark,
})

export type CSS = Stitches.CSS<typeof config>
