import { styled } from "@/stitches"

import { Box } from "./box"
import { gapVariants } from "./utils/gap"

export const Stack = styled(Box, gapVariants, {
  variants: {
    inline: {
      true: { display: "inline-flex" },
      false: { display: "flex" },
    },
    direction: {
      row: { flexDirection: "row" },
      column: { flexDirection: "column" },
      rowReverse: { flexDirection: "row-reverse" },
      columnReverse: { flexDirection: "column-reverse" },
    },
    align: {
      start: { alignItems: "flex-start" },
      center: { alignItems: "center" },
      end: { alignItems: "flex-end" },
      stretch: { alignItems: "stretch" },
      baseline: { alignItems: "baseline" },
    },
    justify: {
      start: { justifyContent: "flex-start" },
      center: { justifyContent: "center" },
      end: { justifyContent: "flex-end" },
      between: { justifyContent: "space-between" },
    },
    wrap: {
      true: { flexWrap: "wrap" },
      false: { flexWrap: "nowrap" },
    },
  },
  defaultVariants: {
    inline: false,
    direction: "row",
    align: "stretch",
    justify: "start",
    wrap: false,
  },
})
