import { MessageDescriptor, i18n } from "@lingui/core"
import { ZodErrorMap, ZodIssueCode, ZodIssueOptionalMessage } from "zod"

import * as ValidationMessages from "./messages"

function getMessage(issue: ZodIssueOptionalMessage): MessageDescriptor | null {
  switch (issue.code) {
    case ZodIssueCode.invalid_type:
      if (issue.received === "undefined") {
        return ValidationMessages.required
      }

      if (issue.expected === "integer") {
        return ValidationMessages.mustBeNumber
      }

      if (issue.expected === "number") {
        return ValidationMessages.mustBeNumber
      }

      return ValidationMessages.invalidValue

    case ZodIssueCode.invalid_literal:
      if (issue.expected === true) {
        return ValidationMessages.mustBeChecked
      }

    case ZodIssueCode.invalid_string:
      return ValidationMessages.invalidEmail

    case ZodIssueCode.too_small:
      if (issue.type === "string") {
        if (issue.inclusive && issue.minimum === 1) {
          return ValidationMessages.mustBeNonEmpty
        }

        return ValidationMessages.stringTooShort(
          issue.minimum as number,
          issue.inclusive
        )
      }

      if (issue.type === "number") {
        return ValidationMessages.numberTooSmall(
          issue.minimum as number,
          issue.inclusive
        )
      }

      return ValidationMessages.invalidValue

    case ZodIssueCode.too_big:
      if (issue.type === "string") {
        return ValidationMessages.stringTooLong(
          issue.maximum as number,
          issue.inclusive
        )
      }

      if (issue.type === "number") {
        return ValidationMessages.numberTooBig(
          issue.maximum as number,
          issue.inclusive
        )
      }

      return ValidationMessages.invalidValue
  }

  return null
}

/**
 * Customize Zod validation validationMessages.
 *
 * [Zod error handling reference](https://github.com/colinhacks/zod/blob/master/ERROR_HANDLING.md)
 * [Default error map](https://github.com/colinhacks/zod/blob/master/src/ZodError.ts#L259)
 */
export const errorMap: ZodErrorMap = (issue, ctx) => {
  const messageDescriptor = getMessage(issue)

  return {
    message: messageDescriptor ? i18n._(messageDescriptor) : ctx.defaultError,
  }
}
