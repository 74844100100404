import * as LabelPrimitive from "@radix-ui/react-label"

import { styled } from "@/stitches"

import { Text } from "./text"

export const Label = styled(LabelPrimitive.Root, Text, {
  defaultVariants: {
    color: "muted",
    size: "sm",
  },
})
