import { css } from "@/stitches"

export const gapVariants = css({
  variants: {
    gap: {
      1: { gap: "$1" },
      2: { gap: "$2" },
      3: { gap: "$3" },
      4: { gap: "$4" },
      5: { gap: "$5" },
      6: { gap: "$6" },
      7: { gap: "$7" },
      8: { gap: "$8" },
      9: { gap: "$9" },
      10: { gap: "$10" },
      12: { gap: "$12" },
      14: { gap: "$14" },
      16: { gap: "$16" },
      24: { gap: "$24" },
      32: { gap: "$32" },
      48: { gap: "$48" },
      64: { gap: "$64" },
    },
    rowGap: {
      1: { rowGap: "$1" },
      2: { rowGap: "$2" },
      3: { rowGap: "$3" },
      4: { rowGap: "$4" },
      5: { rowGap: "$5" },
      6: { rowGap: "$6" },
      7: { rowGap: "$7" },
      8: { rowGap: "$8" },
      9: { rowGap: "$9" },
      10: { rowGap: "$10" },
      12: { rowGap: "$12" },
      14: { rowGap: "$14" },
      16: { rowGap: "$16" },
      24: { rowGap: "$24" },
      32: { rowGap: "$32" },
      48: { rowGap: "$48" },
      64: { rowGap: "$64" },
    },
    columnGap: {
      1: { columnGap: "$1" },
      2: { columnGap: "$2" },
      3: { columnGap: "$3" },
      4: { columnGap: "$4" },
      5: { columnGap: "$5" },
      6: { columnGap: "$6" },
      7: { columnGap: "$7" },
      8: { columnGap: "$8" },
      9: { columnGap: "$9" },
      10: { columnGap: "$10" },
      12: { columnGap: "$12" },
      14: { columnGap: "$14" },
      16: { columnGap: "$16" },
      24: { columnGap: "$24" },
      32: { columnGap: "$32" },
      48: { columnGap: "$48" },
      64: { columnGap: "$64" },
    },
  },
})
