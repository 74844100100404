import { i18n } from "@lingui/core"
import { t } from "@lingui/macro"
import { I18nProvider } from "@lingui/react"
import * as Sentry from "@sentry/nextjs"
import { HTTPError, TimeoutError } from "ky"
import { cs, de, en } from "make-plural/plurals"
import type { AppProps } from "next/app"
import Head from "next/head"
import { useRouter } from "next/router"
import { useEffect } from "react"
import { QueryClient, QueryClientProvider, setLogger } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"
import { z } from "zod"

import { env } from "@/config/env"
import { CookieConsent } from "@/domains/cookie-consent/view"
import { openErrorToast } from "@/domains/toasts/store"
import { systemColorSchemeChanged } from "@/domains/website-settings/store"
import { GtmScript, usePageView } from "@/lib/gtm"
import { loadLocale } from "@/lib/i18n"
import { errorMap } from "@/lib/validation/error-map"
import { globalStyles } from "@/stitches/global"
import * as Toast from "@/ui/toast"

// -----------------------------------------------------------------------------
// Global styles
// -----------------------------------------------------------------------------

globalStyles()

// -----------------------------------------------------------------------------
// Internationalization
// -----------------------------------------------------------------------------

i18n.loadLocaleData("en", { plurals: en })
i18n.loadLocaleData("cs", { plurals: cs })
i18n.loadLocaleData("de", { plurals: de })

// -----------------------------------------------------------------------------
// React query
// -----------------------------------------------------------------------------

function shouldRetryOnError(error: unknown): boolean {
  return error instanceof TimeoutError
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // Make all loaded data stale after 10 seconds
      staleTime: 10 * 1000,
      retry: (_, error) => shouldRetryOnError(error),
      onError: (error) => {
        openErrorToast(error)
      },
    },
  },
})

setLogger({
  log(message) {
    /* eslint-disable-next-line no-console */
    console.log(message)
    Sentry.captureMessage(message, { level: Sentry.Severity.Log })
  },
  warn(message) {
    /* eslint-disable-next-line no-console */
    console.warn(message)
    Sentry.captureMessage(message, { level: Sentry.Severity.Warning })
  },
  error(error) {
    /* eslint-disable-next-line no-console */
    console.error(error)

    if (
      error instanceof HTTPError &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      // Don't log HTTP errors with 4** code.
      return
    }

    // Log everything else to Sentry.
    Sentry.captureException(error)
  },
})

// -----------------------------------------------------------------------------
// Form validation messages
// -----------------------------------------------------------------------------

z.setErrorMap(errorMap)

// -----------------------------------------------------------------------------
// Canonical URL
// -----------------------------------------------------------------------------

/**
 * Source: https://rishimohan.me/blog/nextjs-canonical-tag
 */
function useCanonicalUrl() {
  const router = useRouter()
  const canonicalPath = router.asPath.split("?")[0]

  return `${env.ORIGIN}${canonicalPath}`
}

// -----------------------------------------------------------------------------
// Application
// -----------------------------------------------------------------------------

export default function App({ Component, pageProps }: AppProps) {
  const { locale } = useRouter()

  usePageView(env.GTM_ID)

  useEffect(() => {
    if (locale) {
      loadLocale(locale)
    }
  }, [locale])

  useEffect(() => {
    const query = window.matchMedia("(prefers-color-scheme: dark)")

    query.addEventListener("change", (event) => {
      systemColorSchemeChanged(event.matches ? "dark" : "light")
    })

    systemColorSchemeChanged(query.matches ? "dark" : "light")
  })

  const canonicalUrl = useCanonicalUrl()

  return (
    <>
      {env.GTM_ID && <GtmScript id={env.GTM_ID} />}
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        {/* Can be overridden with more specific title later in the app. */}
        <title key="title">{env.APP_NAME}</title>
        <meta
          name="description"
          content={t({
            id: "metadata",
            message:
              "Online aukční galerie s kvalitní nabídkou mezinárodního umění. Dražte a nakupujte umělecká díla na nejpokročilejším aukčním portálu z pohodlí Vašeho domova.",
          })}
        />
        <link rel="canonical" href={canonicalUrl} />
        <link rel="og:url" href={canonicalUrl} />
        <meta property="og:site_name" content={env.APP_NAME} />
        <meta property="og:locale" content={locale} />
      </Head>
      <QueryClientProvider client={queryClient}>
        <I18nProvider i18n={i18n}>
          <Toast.Provider swipeDirection="right">
            <CookieConsent />
            <Component {...pageProps} />
          </Toast.Provider>
        </I18nProvider>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </>
  )
}
