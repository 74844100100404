import { CSS } from "@/stitches"
import { Box } from "@/ui/box"

export function Line({ css }: { css?: CSS }) {
  return (
    <Box
      as="svg"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      width="1.25em"
      css={css}
    >
      <line
        x1="0"
        y1="11"
        x2="24"
        y2="11"
        strokeWidth={3}
        stroke="currentColor"
      />
    </Box>
  )
}
