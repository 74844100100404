import { range } from "fp-ts/NonEmptyArray"

import { keyframes, styled } from "@/stitches"

import { Stack } from "./stack"

export const pulse = keyframes({
  "0%": { opacity: 1 },
  "50%": { opacity: 0.5 },
  "100%": { opacity: 1 },
})

export const Skeleton = styled("div", {
  bgColor: "$gray6",
  "@animation": {
    animation: `${pulse} 2s ease-in-out infinite`,
  },
  variants: {
    text: {
      false: { borderRadius: 0 },
      true: { borderRadius: 4 },
    },
  },
})

const TableRowSkeleton = styled(Skeleton, {
  height: 32,
})

export function TableSkeleton({ rows = 6 }: { rows?: number }) {
  return (
    <Stack direction="column" gap={4}>
      {range(1, rows).map((key) => (
        <TableRowSkeleton key={key} text />
      ))}
    </Stack>
  )
}

export function ParagraphSkeleton({ lines = 6 }: { lines: number }) {
  return (
    <Stack direction="column" gap={2}>
      {range(1, lines).map((key) => (
        <Skeleton
          key={key}
          text
          css={{ height: 24, width: key < lines ? "100%" : "50%" }}
        />
      ))}
    </Stack>
  )
}
