import { pipe } from "fp-ts/function"
import * as D from "io-ts/Decoder"

import { LocalizedString } from "@/domains/localized-strings/model"
import { Uuid } from "@/lib/uuid"

export type CurrencyCode = string & { readonly CurrencyCode: unique symbol }

export const CurrencyCode = pipe(
  D.string,
  D.refine((value): value is CurrencyCode => true, "Uuid")
)

export const defaultCurrencyCode: CurrencyCode = "EUR" as CurrencyCode

export const Currency = D.struct({
  id: Uuid,
  isoCode4217: CurrencyCode,
  name: LocalizedString,
  rateOneEuro: D.number,
})

export interface Currency<C extends CurrencyCode> {
  id: Uuid
  isoCode4217: C
  name: LocalizedString
  rateOneEuro: number
}

export const toSelectOption = <C extends CurrencyCode>(
  currency: Currency<C>
) => ({
  value: currency.isoCode4217,
  display: currency.isoCode4217,
})

export const findByCode =
  <C extends CurrencyCode>(currencyCode: C) =>
  (currencies: Array<Currency<C>>): Currency<C> | undefined =>
    currencies.find((currency) => currency.isoCode4217 === currencyCode)
