import { ReactNode } from "react"
import InformationIcon from "remixicon-react/InformationLineIcon"

import { useId } from "@/lib/hooks/use-id"
import { CSS, styled } from "@/stitches"
import { Text } from "@/ui/text"

import { Label } from "./label"
import { Stack } from "./stack"

export const Root = styled(Stack, {
  ".input-hint": {
    display: "none",
  },

  "&:focus-within .input-hint": {
    display: "flex",
  },

  defaultVariants: {
    direction: "column",
    gap: 2,
  },
})

export const ErrorMessage = styled("p", {
  fontSize: "$sm",
  fontWeight: "$medium",
  color: "$danger11",
})

export type FieldBaseProps = {
  children: ReactNode | ((inputProps: { id: string }) => ReactNode)
  label?: ReactNode
  afterLabel?: ReactNode
  required?: boolean
  error?: ReactNode
  hint?: ReactNode
  css?: CSS
}

export function FieldBase(props: FieldBaseProps) {
  const {
    children,
    label,
    error,
    hint,
    afterLabel,
    required = false,
    css,
  } = props
  const id = useId()

  return (
    <Root css={css}>
      {label && (
        <Stack justify="between" align="center">
          <Label htmlFor={id}>
            {label}
            {required && (
              <Text color="danger" weight="bold" css={{ ml: "$1" }}>
                *
              </Text>
            )}
          </Label>
          {afterLabel}
        </Stack>
      )}
      {typeof children === "function" ? children({ id }) : children}
      {error ? (
        <ErrorMessage>{error}</ErrorMessage>
      ) : hint ? (
        <Text
          className="input-hint"
          as={Stack}
          align="center"
          gap={2}
          color="muted"
          size="sm"
        >
          <InformationIcon size="1.25em" />
          <Text as="p">{hint}</Text>
        </Text>
      ) : null}
    </Root>
  )
}
