import { zodResolver } from "@hookform/resolvers/zod"
import {
  FieldErrors,
  FieldValues,
  UseFormProps,
  UseFormReturn,
  useForm as useForm_,
} from "react-hook-form"
import { Schema } from "zod"

import { Merge } from "./type-utils"

export type Form<T extends FieldValues> = UseFormReturn<T> & {
  errors: FieldErrors<Merge<T>>
}

export function useForm<T extends FieldValues>(
  schema: Schema<T>,
  options?: UseFormProps<T>
): Form<T> {
  const form = useForm_({
    resolver: zodResolver(schema),
    ...options,
  })

  const errors = form.formState.errors as FieldErrors<
    // Merge discriminated unions so we can access error messages easily
    Merge<T>
  >

  return {
    ...form,
    errors,
  }
}
