import { record, string } from "fp-ts"
import { not } from "fp-ts/Predicate"
import { pipe } from "fp-ts/function"
import * as D from "io-ts/Decoder"

export const LocalizedString = pipe(
  D.record(D.string),
  D.map(record.filter(not(string.isEmpty))) // Filter out entries with empty string values
)

export type LocalizedString = D.TypeOf<typeof LocalizedString>
