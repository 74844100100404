import { ComponentProps, ReactNode, forwardRef } from "react"
import HelpIcon from "remixicon-react/QuestionLineIcon"

import { useId } from "@/lib/hooks/use-id"
import { styled } from "@/stitches"
import { Tooltip } from "@/ui/tooltip"

import { ErrorMessage } from "./field-base"
import { Stack } from "./stack"
import { Text } from "./text"

export const CheckboxStyled = styled("input", {
  all: "unset",
  margin: 0,
  font: "inherit",
  color: "currentColor",
  width: "1.5em",
  height: "1.5em",
  border: "2px solid $gray12",
  transform: "translateY(-0.075em)",
  display: "grid",
  placeContent: "center",
  transition: "background-color 120ms, border-color 120ms, box-shadow 120ms",
  flexShrink: 0,

  "&:not(:disabled)": {
    cursor: "pointer",
  },

  "&::before": {
    content: '""',
    width: "1.25em",
    height: "1.25em",
    clipPath:
      "polygon(40% 60%, 24% 44%, 16% 52%, 40% 76%, 84% 32%, 76% 24%, 40% 60%)",
    transform: "scale(0)",
    transition: "120ms transform ease-in-out",
    backgroundColor: "$gray1",
  },

  "&:checked": {
    bgColor: "$accent9",
    borderColor: "$accent9",
  },

  "&:checked::before": {
    transform: "scale(1)",
  },

  "&:focus": {
    boxShadow: "$ring",
  },

  "&:disabled": {
    cursor: "not-allowed",
    bgColor: "$gray4",
    borderColor: "transparent",
  },
})

export type CheckboxProps = ComponentProps<typeof CheckboxStyled> & {
  onCheckedChange?: (checked: boolean) => void
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  function CheckboxForwarded(props, ref) {
    const { onCheckedChange, onChange, ...restProps } = props
    return (
      <CheckboxStyled
        type="checkbox"
        onChange={
          onCheckedChange
            ? (event) => onCheckedChange(event.target.checked)
            : onChange
        }
        {...restProps}
        ref={ref}
      />
    )
  }
)

export type LabelledCheckboxProps = CheckboxProps & {
  children: ReactNode
  error?: string
  hint?: ReactNode
}

export const LabelledCheckbox = forwardRef<
  HTMLInputElement,
  LabelledCheckboxProps
>(function LabelledCheckboxForwarded(
  { children, error, css, hint, ...props },
  ref
) {
  const id = useId()

  return (
    <Stack direction="column" gap={2} css={css}>
      <Stack align="center" gap="4">
        <Checkbox type="checkbox" id={id} {...props} ref={ref} />
        <Text as="label" size="sm" weight="medium" htmlFor={id}>
          {children}
        </Text>
        {hint && (
          <Tooltip content={hint} asChild>
            <Text as="button" type="button" color="muted">
              <HelpIcon size="1em" />
            </Text>
          </Tooltip>
        )}
      </Stack>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Stack>
  )
})
