export const zIndices = {
  hide: -1,
  auto: "auto",
  base: 0,
  docked: 10,
  overlay: 1000,
  modal: 1100,
  dropdown: 1200,
  sticky: 1300,
  banner: 1400,
  popover: 1500,
  select: 1600,
  toast: 1700,
  tooltip: 1800,
}
