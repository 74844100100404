import { MessageDescriptor } from "@lingui/core"
import { defineMessage } from "@lingui/macro"
import * as D from "io-ts/Decoder"

export const LengthUnit = D.union(
  D.literal("cm"), //
  D.literal("inch")
)

export type LengthUnit = D.TypeOf<typeof LengthUnit>

export const units: Array<LengthUnit> = ["cm", "inch"]

export const displayUnit = (unit: LengthUnit) =>
  ({
    cm: defineMessage({
      id: "lengthUnits.centimeters",
      message: "Centimetry",
    }),
    inch: defineMessage({
      id: "lengthUnits.inches",
      message: "Palce",
    }),
  }[unit])

export const lengthUnitOptions: Array<{
  display: MessageDescriptor
  value: string
}> = units.map((code) => ({
  display: displayUnit(code),
  value: code,
}))

export const cmToUnitRatio = (unit: LengthUnit): number =>
  ({
    cm: 1,
    inch: 0.393701,
  }[unit])

export const defaultLengthUnit: LengthUnit = "cm"
