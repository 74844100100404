import { styled } from "@/stitches"
import { gapVariants } from "@/ui/utils/gap"

import { Box } from "./box"

export const Grid = styled(Box, gapVariants, {
  display: "grid",
  variants: {
    align: {
      start: { alignItems: "start" },
      center: { alignItems: "center" },
      end: { alignItems: "end" },
      stretch: { alignItems: "stretch" },
      baseline: { alignItems: "baseline" },
    },
    justify: {
      start: { justifyContent: "start" },
      center: { justifyContent: "center" },
      end: { justifyContent: "end" },
      between: { justifyContent: "space-between" },
    },
    flow: {
      row: { gridAutoFlow: "row" },
      column: { gridAutoFlow: "column" },
      dense: { gridAutoFlow: "dense" },
      rowDense: { gridAutoFlow: "row dense" },
      columnDense: { gridAutoFlow: "column dense" },
    },
    placeItems: {
      center: { placeItems: "center" },
    },
    columns: {
      1: { gridTemplateColumns: "repeat(1, 1fr)" },
      2: { gridTemplateColumns: "repeat(2, 1fr)" },
      3: { gridTemplateColumns: "repeat(3, 1fr)" },
      4: { gridTemplateColumns: "repeat(4, 1fr)" },
      6: { gridTemplateColumns: "repeat(6, 1fr)" },
      8: { gridTemplateColumns: "repeat(8, 1fr)" },
      12: { gridTemplateColumns: "repeat(12, 1fr)" },
    },
  },
})
