import { either } from "fp-ts"
import { pipe } from "fp-ts/function"
import * as D from "io-ts/Decoder"

import { GtmId } from "@/lib/gtm"

/**
 * How to add environment variables:
 * 1. Add it to the `schema`
 * 2. Add mapping to the `process.env.*` in `schema.decode`
 *
 * Environment variables bypassing this validation for they're used in node scripts:
 * - process.env.NODE_ENV (optional)
 * - process.env.NEXT_PUBLIC_SENTRY_DSN (optional, sentry is turned off if not defined)
 */

/**
 * Enviornment variables schema which actual variables will be validated with.
 */
const schema = pipe(
  /**
   * Required variables.
   */
  D.struct({
    API_URL: D.string,
    CLIENT_ID: D.string,
    CLIENT_SECRET: D.string,
    GOOGLE_MAPS_API_KEY: D.string,
    ORIGIN: D.string,
    VERIFF_API_KEY: D.string,
  }),

  /**
   * Optional variables.
   */
  D.intersect(
    D.partial({
      APP_NAME: D.string,
      APP_CODE: D.string,
      GTM_ID: GtmId,
    })
  ),

  /**
   * Defaults for optional variables.
   */
  D.map((vars) => ({
    ...vars,
    APP_NAME: vars.APP_NAME ?? "Art Investment Gallery",
    APP_CODE: vars.APP_CODE ?? "aig",
  }))
)

// They have need to be listed out like this instead of passing just `process.env`,
// because variables are evaluated at build time and `process.env.*` is directly replaced by actual value.
// See https://nextjs.org/docs/basic-features/environment-variables#loading-environment-variables
const result = schema.decode({
  API_URL: process.env.NEXT_PUBLIC_API_URL,
  APP_CODE: process.env.NEXT_PUBLIC_APP_CODE,
  APP_NAME: process.env.NEXT_PUBLIC_APP_NAME,
  CLIENT_ID: process.env.NEXT_PUBLIC_CLIENT_ID,
  CLIENT_SECRET: process.env.NEXT_PUBLIC_CLIENT_SECRET,
  GTM_ID: process.env.NEXT_PUBLIC_GTM_ID,
  GOOGLE_MAPS_API_KEY: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
  ORIGIN: process.env.NEXT_PUBLIC_ORIGIN,
  VERIFF_API_KEY: process.env.NEXT_PUBLIC_VERIFF_API_KEY,
})

if (either.isLeft(result)) {
  throw new Error(`Failed to decode environment variables:
${D.draw(result.left)}`)
}

export const env = result.right
