import * as ToastPrimitive from "@radix-ui/react-toast"
import { useStore } from "effector-react"
import AlertLineIcon from "remixicon-react/AlertLineIcon"
import CheckboxCircleLineIcon from "remixicon-react/CheckboxCircleLineIcon"
import CloseIcon from "remixicon-react/CloseLineIcon"
import InformationLineIcon from "remixicon-react/InformationLineIcon"

import * as ToastStore from "@/domains/toasts/store"
import { useI18n } from "@/lib/i18n"
import { hiContrastDarkTheme, keyframes, styled } from "@/stitches"

import { IconButton } from "./button"

const VIEWPORT_PADDING = "1.5em"

const hide = keyframes({
  "0%": { opacity: 1 },
  "100%": { opacity: 0 },
})

const slideIn = keyframes({
  from: { transform: `translateX(calc(100% + ${VIEWPORT_PADDING}))` },
  to: { transform: "translateX(0)" },
})

const swipeOut = keyframes({
  from: { transform: "translateX(var(--radix-toast-swipe-end-x))" },
  to: { transform: `translateX(calc(100% + ${VIEWPORT_PADDING}))` },
})

const ToastViewport = styled(ToastPrimitive.Viewport, {
  position: "fixed",
  bottom: 0,
  right: 0,
  display: "flex",
  flexDirection: "column",
  padding: VIEWPORT_PADDING,
  gap: 10,
  width: 420,
  maxWidth: "100vw",
  margin: 0,
  listStyle: "none",
  zIndex: "$toast",
})

const ToastRoot = styled(ToastPrimitive.Root, {
  boxShadow: "$shadow1",
  padding: "$6",
  display: "grid",
  gridTemplateAreas: `
    "icon title action"
    "icon description action"
  `,
  gridTemplateColumns: "min-content auto max-content",
  columnGap: 15,
  alignItems: "center",
  backdropFilter: "blur(6px)",
  border: "1px solid transparent",

  "@media (prefers-reduced-motion: no-preference)": {
    '&[data-state="open"]': {
      animation: `${slideIn} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
    },
    '&[data-state="closed"]': {
      animation: `${hide} 100ms ease-in forwards`,
    },
    '&[data-swipe="move"]': {
      transform: "translateX(var(--radix-toast-swipe-move-x))",
    },
    '&[data-swipe="cancel"]': {
      transform: "translateX(0)",
      transition: "transform 200ms ease-out",
    },
    '&[data-swipe="end"]': {
      animation: `${swipeOut} 100ms ease-out forwards`,
    },
  },

  variants: {
    severity: {
      info: {
        bgColor: "$popoverBg",
        borderColor: "$popoverBorder",
      },
      danger: {
        bgColor: "$popoverBg",
        borderColor: "$popoverBorder",
      },
      success: {
        bgColor: "$tealA9",
      },
    },
  },

  defaultVariants: {
    severity: "info",
  },
})

const ToastTitle = styled(ToastPrimitive.Title, {
  gridArea: "title",
  marginBottom: 5,
  fontWeight: 500,
})

const ToastDescription = styled(ToastPrimitive.Description, {
  gridArea: "description",
  margin: 0,
  fontSize: "$xs",
  fontWeight: "$medium",
  color: "$gray11",
})

const ToastAction = styled(ToastPrimitive.Action, {
  gridArea: "action",
  placeSelf: "start",
  mt: "-$2",
  mr: "-$2",
})

const ToastIcon = styled("div", {
  gridArea: "icon",
  color: "$gray11",
})

function Toast({ toast }: { toast: ToastStore.Toast }) {
  const { i18n } = useI18n()
  const { severity = "info" } = toast
  const isOpen = useStore(ToastStore.$isOpen)

  const themeClass = severity === "success" ? hiContrastDarkTheme : undefined

  const icon = {
    danger: <AlertLineIcon />,
    success: <CheckboxCircleLineIcon />,
    info: <InformationLineIcon />,
  }[severity] ?? <InformationLineIcon />

  return (
    <ToastRoot
      open={isOpen}
      onOpenChange={ToastStore.setOpen}
      severity={toast.severity}
    >
      <ToastTitle className={themeClass}>{i18n._(toast.message)}</ToastTitle>
      <ToastDescription asChild className={themeClass}>
        <time dateTime={toast.timestamp.toISOString()}>
          {i18n.date(toast.timestamp, {
            dateStyle: "medium",
            timeStyle: "short",
          })}
        </time>
      </ToastDescription>
      <ToastAction
        asChild
        altText="Goto schedule to undo"
        className={themeClass}
      >
        <IconButton size="sm">
          <CloseIcon size="1.25em" />
        </IconButton>
      </ToastAction>
      <ToastIcon className={themeClass}>{toast.icon ?? icon}</ToastIcon>
    </ToastRoot>
  )
}

export function Provider({
  children,
  ...props
}: ToastPrimitive.ToastProviderProps) {
  const toast = useStore(ToastStore.$toast)

  return (
    <ToastPrimitive.Provider {...props}>
      {children}
      {toast && <Toast toast={toast} />}
      <ToastViewport />
    </ToastPrimitive.Provider>
  )
}
