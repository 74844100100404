const shadows = {
  ring: "0 0 0 4px $colors$focusRing",
  top: "0 -0.5rem 1rem $colors$gray1",
}

export const shadowsLight = {
  ...shadows,
  shadow1: [
    "hsl(206 22% 7% / 35%) 0px 10px 38px -10px",
    "hsl(206 22% 7% / 20%) 0px 10px 20px -15px",
    "hsl(206 22% 7% / 10%) 0px 2px 4px 0",
  ].join(","),
}

export const shadowsDark = {
  ...shadows,
  shadow1: "none",
}
