import Icon from "remixicon-react/Loader5LineIcon"

import { keyframes, styled } from "@/stitches"

const rotate = keyframes({
  "0%": { transform: "rotate(0deg)", opacity: 1 },
  "50%": { opacity: 0.6 },
  "100%": { transform: "rotate(360deg)", opacity: 1 },
})

export const Throbber = styled(Icon, {
  width: "var(--throbber-size)",
  height: "var(--throbber-size)",
  animation: `${rotate} 1s ease-in-out infinite`,

  variants: {
    size: {
      xs: { "--throbber-size": "1.25em" },
      sm: { "--throbber-size": "1.5em" },
      md: { "--throbber-size": "2em" },
      lg: { "--throbber-size": "2.5em" },
    },
  },

  defaultVariants: {
    size: "sm",
  },
})
