import * as DialogPrimitive from "@radix-ui/react-dialog"
import { record } from "fp-ts"
import { pipe } from "fp-ts/function"
import { ComponentProps, ReactNode } from "react"
import CloseIcon from "remixicon-react/CloseLineIcon"

import { keyframes, styled } from "@/stitches"
import { containerSizes } from "@/stitches/theme/size"

import { IconButton } from "./button"
import { Grid } from "./grid"
import { Title } from "./text"

// -----------------------------------------------------------------------------
// Primitives
// -----------------------------------------------------------------------------

const overlayShow = keyframes({
  "0%": { opacity: 0 },
  "100%": { opacity: 1 },
})

const OverlayStyled = styled(DialogPrimitive.Overlay, {
  position: "fixed",
  inset: 0,
  zIndex: "$overlay",
  bgColor: "$blackA11",
  backdropFilter: "blur(6px)",
  "@animation": {
    animation: `${overlayShow} 600ms cubic-bezier(0.16, 1, 0.3, 1)`,
  },
})

const contentShow = {
  slideCenter: keyframes({
    "0%": { opacity: 0, transform: "translate(-50%, -48%) scale(.96)" },
    "100%": { opacity: 1, transform: "translate(-50%, -50%) scale(1)" },
  }),
  slideTop: keyframes({
    "0%": { opacity: 0, transform: "translate(0%, -10%) scale(.96)" },
    "100%": { opacity: 1, transform: "translate(0%, 0%) scale(1)" },
  }),
  slideRight: keyframes({
    "0%": { opacity: 0, transform: "translate(10%, 0%) scale(.96)" },
    "100%": { opacity: 1, transform: "translate(0%, 0%) scale(1)" },
  }),
}

const TitleStyled = styled(DialogPrimitive.Title, {
  pb: "$2",
  pt: "$3",
  gridArea: "title",
})

const DescriptionStyled = styled(DialogPrimitive.Description, {
  gridArea: "description",
  pb: "$2",
  fontSize: "$sm",
  color: "$gray11",
})

const CloseStyled = styled(DialogPrimitive.Close, {
  gridArea: "close",
})

const ContentBaseStyled = styled(DialogPrimitive.Content, {
  zIndex: "$modal",
  color: "$gray12",
  bgColor: "$gray1",
  boxShadow: "$shadow1",
})

const ContentStyled = styled(ContentBaseStyled, {
  position: "fixed",
  width: "100%",
  maxHeight: "100%",
  display: "grid",
  gridTemplateRows: "auto 1fr auto",
  gridTemplateAreas: `"header"
    "body"
    "footer"`,

  top: 0,
  left: 0,
  "@animation": {
    animation: `${contentShow.slideTop} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
  },

  "&:focus": {
    outline: "none",
  },

  variants: {
    size: pipe(
      containerSizes,
      record.mapWithIndex((key) => ({ maxWidth: `$${key}` }))
    ),
    spacing: {
      normal: {
        "--dialog-spacing": "var(--space-6)",
        "@md": { "--dialog-spacing": "var(--space-8)" },
      },
    },
    position: {
      center: {
        "@sm": {
          width: "90vw",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxHeight: "calc(100vh - 2rem)",
          "@animation": {
            animation: `${contentShow.slideCenter} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
          },
        },
      },
      right: {
        "@sm": {
          right: 0,
          top: 0,
          bottom: 0,
          left: "auto",
          "@animation": {
            animation: `${contentShow.slideRight} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
          },
        },
      },
    },
  },

  defaultVariants: {
    size: "sm",
    spacing: "normal",
    position: "center",
  },
})

const HeaderStyled = styled("header", Grid, {
  gridArea: "header",
  pb: "$6",
  pt: "var(--dialog-spacing)",
  px: "var(--dialog-spacing)",
  gridTemplateColumns: "1fr auto",
  gridTemplateAreas: '"title close" "description description"',
  gridColumnGap: "$4",
  position: "sticky",
  top: 0,
  bgColor: "$gray1",
})

const BodyStyled = styled("div", {
  gridArea: "body",
  position: "relative",
  pb: "var(--dialog-spacing)",
  overflowX: "hidden",
  overflowY: "scroll",
  pt: "$1",

  variants: {
    compact: {
      true: {},
      false: {
        px: "var(--dialog-spacing)",
      },
    },
  },

  defaultVariants: {
    compact: false,
  },
})

const FooterStyled = styled("div", {
  gridArea: "footer",
  pb: "var(--dialog-spacing)",
  pt: "$6",

  variants: {
    compact: {
      true: {},
      false: {
        px: "var(--dialog-spacing)",
      },
    },
  },

  defaultVariants: {
    compact: false,
  },
})

// -----------------------------------------------------------------------------
// Exports
// -----------------------------------------------------------------------------

/**
 * Anatomy:
 *
 * - Dialog
 *   - Trigger
 *   - Content
 *     - Header
 *     - Body
 *     - Footer
 */
export const Root = DialogPrimitive.Root

export const Overlay = OverlayStyled

export const Trigger = DialogPrimitive.Trigger

export const ContentBase = ContentBaseStyled

export type DialogContentProps = ComponentProps<typeof ContentStyled>

export function Content(props: DialogContentProps) {
  return (
    <DialogPrimitive.Portal>
      <OverlayStyled />
      <ContentStyled {...props} />
    </DialogPrimitive.Portal>
  )
}

export type DialogHeaderProps = {
  children?: ReactNode
  description?: string
}

export function Header({ children, description }: DialogHeaderProps) {
  return (
    <HeaderStyled>
      <CloseStyled asChild>
        <IconButton>
          <CloseIcon size="1.5em" />
        </IconButton>
      </CloseStyled>
      {children && (
        <TitleStyled>
          <Title>{children}</Title>
        </TitleStyled>
      )}
      {description && <DescriptionStyled>{description}</DescriptionStyled>}
    </HeaderStyled>
  )
}

export const Body = BodyStyled

export const Footer = FooterStyled
