export const media = {
  sm: "(min-width: 30em)", // 480px
  md: "(min-width: 48em)", // 768px
  lg: "(min-width: 62em)", // 992px
  xl: "(min-width: 80em)", // 1280px
  "2xl": "(min-width: 96em)", // 1536px
  "3xl": "(min-width: 112em)", // 1792px
  menu0: "(min-width: 24em)",
  menu1: "(min-width: 42em)",
  menu2: "(min-width: 68em)",
  menu3: "(min-width: 80em)",
  gallery1: "(min-width: 62rem)",
  animation: "(prefers-reduced-motion: no-preference)",
}
