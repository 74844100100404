import { styled } from "@/stitches"

export const Box = styled("div", {
  variants: {
    position: {
      relative: { position: "relative" },
      absolute: { position: "absolute" },
      fixed: { position: "fixed" },
      sticky: { position: "sticky" },
    },
    inset: {
      0: { inset: 0 },
    },
    textAlign: {
      left: { textAlign: "left" },
      right: { textAlign: "right" },
      center: { textAlign: "center" },
      justify: { textAlign: "justify" },
    },
    bg: {
      gray1: { backgroundColor: "$gray1" },
    },
    // https://alistapart.com/article/axiomatic-css-and-lobotomized-owls/
    spacing: {
      1: { "& > * + *": { mt: "$1" } },
      2: { "& > * + *": { mt: "$2" } },
      3: { "& > * + *": { mt: "$3" } },
      4: { "& > * + *": { mt: "$4" } },
      5: { "& > * + *": { mt: "$5" } },
      6: { "& > * + *": { mt: "$6" } },
      7: { "& > * + *": { mt: "$7" } },
      8: { "& > * + *": { mt: "$8" } },
      9: { "& > * + *": { mt: "$9" } },
      10: { "& > * + *": { mt: "$10" } },
      12: { "& > * + *": { mt: "$12" } },
      14: { "& > * + *": { mt: "$14" } },
      16: { "& > * + *": { mt: "$16" } },
      24: { "& > * + *": { mt: "$24" } },
      32: { "& > * + *": { mt: "$32" } },
      48: { "& > * + *": { mt: "$48" } },
      64: { "& > * + *": { mt: "$64" } },
    },
  },
})
