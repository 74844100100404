import { ComponentProps, ReactNode, forwardRef } from "react"

import { styled } from "@/stitches"

import { Throbber } from "./throbber"

// -----------------------------------------------------------------------------
// Primitives
// -----------------------------------------------------------------------------

const StyledContent = styled("span", {
  display: "flex",
  alignItems: "center",
  gap: "0.75em",
})

const StyledIcon = styled("span", {
  "& svg": {
    width: "1.25em",
  },

  variants: {
    position: {
      before: { ml: "-0.5em" },
      after: { mr: "-0.5em" },
    },
  },
})

const StyledLoadingOverlay = styled("span", {
  position: "absolute",
  inset: 0,
  display: "grid",
  placeItems: "center",
})

const StyledButtonBase = styled("button", {
  position: "relative",
  display: "flex",
  alignItems: "center",
  gap: "0.75em",
  justifyContent: "center",
  boxSizing: "border-box",
  fontWeight: "$medium",
  border: "2px solid transparent",
  outline: "none",
  "@animation": {
    transition:
      "color 120ms, opacity 120ms, background-color 120ms, border-color 120ms, box-shadow 120ms, transform 120ms",
  },
  "&:disabled, &.disabled": {
    cursor: "not-allowed",
    pointerEvents: "none",
    "--button-color": "$colors$gray7",
  },
  "&:focus-visible": {
    boxShadow: "$ring",
  },
  "&:active": {
    transform: "scale(0.98, 0.94) translateY(1%)",
  },
  variants: {
    variant: {
      none: {},
      solid: {
        bgColor: "var(--button-color)",
        borderColor: "transparent",
        color: "$gray1",
      },
      outline: {
        color: "var(--button-color)",
        borderColor: "var(--button-color)",
        "&:not(:disabled):hover, &:not(.disabled):hover": {
          color: "$gray1",
          bgColor: "var(--button-color)",
          borderColor: "transparent",
        },
      },
      ghost: {
        color: "var(--button-color)",
        borderColor: "transparent",
        "&:not(:disabled):hover,  &:not(.disabled):hover": {
          bgColor: "$grayA6",

          ".hi-contrast-dark-theme &, .darkA-theme &": {
            bgColor: "$grayA8",
          },
        },
        "&:active": {
          bgColor: "$grayA4",
        },
      },
    },
    color: {
      none: {},
      // TODO: rename to `accent`
      primary: {
        "--button-color": "$colors$accent9",
        "--button-color-hover": "$colors$accent10",
      },
      // TODO: rename to `default`
      secondary: {
        "--button-color": "$colors$gray12",
        "--button-color-hover": "$colors$gray11",
      },
      tertiary: {
        "--button-color": "$colors$gray2",
        "--button-color-hover": "$colors$gray3",
        color: "$gray12",
      },
      blackA: {
        "--button-color": "$colors$blackA9",
        "--button-color-hover": "$colors$blackA12",
        color: "white",
      },
      muted: {
        "--button-color": "$colors$gray11",
        "--button-color-hover": "$colors$gray12",
      },
      danger: {
        "--button-color": "$colors$danger9",
        "--button-color-hover": "$colors$danger11",
      },
    },
    shadow: {
      true: {
        boxShadow: "$shadow1",
      },
    },
    rounded: {
      true: {
        borderRadius: 100,
      },
    },
    pressable: {
      true: {
        "&:not(:disabled):hover, &:not(.disabled):hover": {
          "--button-color": "var(--button-color-hover)",
        },
      },
      false: {},
    },
  },
  defaultVariants: {
    variant: "none",
    color: "none",
    pressable: true,
  },
})

const StyledButton = styled(StyledButtonBase, {
  variants: {
    variant: {
      none: {},
      solid: {},
      outline: {},
      ghost: {},
    },
    color: {
      none: {},
      primary: {},
      secondary: {},
      blackA: {},
      muted: {},
      danger: {},
    },
    size: {
      sm: {
        fontSize: "$sm",
      },
      md: {},
      lg: {
        fontSize: "$lg",
      },
    },
    density: {
      normal: {
        px: "1.5em",
        py: "0.5em",
      },
      dense: {
        px: "0.75em",
        py: "0.5em",
      },
    },
    minWidth: {
      w1: { minWidth: "8rem" },
    },
    loading: {
      true: {
        [`& > :not(${StyledLoadingOverlay})`]: {
          visibility: "hidden",
        },
      },
    },
  },
  defaultVariants: {
    size: "md",
    density: "normal",
    variant: "solid",
    color: "primary",
  },
})

const StyledIconButton = styled(StyledButtonBase, {
  justifyContent: "center",
  "--button-size": "3.5em",

  variants: {
    variant: {
      solid: {},
      outline: {},
      ghost: {},
    },
    size: {
      xs: {
        "--button-size": "1.5rem",
      },
      sm: {
        "--button-size": "2.5rem",
      },
      sm2: {
        "--button-size": "3rem",
      },
      md: {
        "--button-size": "3.5rem",
      },
      auto: {
        "--button-size": "auto",
      },
    },
    rect: {
      false: {
        borderRadius: "$full",
      },
      true: {},
    },
    active: {
      true: {
        backgroundColor: "$accentA4 !important",
        color: "$accent9",
        fontWeight: "$medium",

        "&:hover": {
          backgroundColor: "$accentA6 !important",
        },
      },
    },
    widthMode: {
      expand: {
        height: "var(--button-size)",
        px: "$4",
      },
      fixed: {
        width: "var(--button-size)",
        height: "var(--button-size)",
      },
    },
  },
  defaultVariants: {
    rect: false,
    variant: "ghost",
    color: "secondary",
    widthMode: "fixed",
  } as any /* eslint-disable-line @typescript-eslint/no-explicit-any */,
})

const StyledIconButtonBadge = styled("span", {
  position: "absolute",
  top: 0,
  right: 0,
})

// -----------------------------------------------------------------------------
// Composites
// -----------------------------------------------------------------------------

export type ButtonProps = ComponentProps<typeof StyledButton> & {
  href?: string
  loading?: boolean
  iconBefore?: ReactNode
  iconAfter?: ReactNode
  as?: any // eslint-disable-line
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  function ButtonForwarded(
    { children, as, iconBefore, iconAfter, loading = false, ...props },
    ref
  ) {
    return (
      <StyledButton as={as} loading={loading} {...props} ref={ref}>
        {iconBefore && <StyledIcon position="before">{iconBefore}</StyledIcon>}
        <StyledContent>{children}</StyledContent>
        {iconAfter && <StyledIcon position="after">{iconAfter}</StyledIcon>}
        {loading && (
          <StyledLoadingOverlay>
            <Throbber />
          </StyledLoadingOverlay>
        )}
      </StyledButton>
    )
  }
)

// -----------------------------------------------------------------------------
// Exports
// -----------------------------------------------------------------------------

export const ButtonBase = StyledButtonBase
export const IconButton = StyledIconButton
export const IconButtonBadge = StyledIconButtonBadge
export { Button }
