import { pipe } from "fp-ts/function"
import * as D from "io-ts/Decoder"

// -----------------------------------------------------------------------------
// Decoders
// -----------------------------------------------------------------------------

export const NumberFromString = pipe(
  D.union(D.number, D.string),
  D.parse((value) => {
    if (typeof value === "number") return D.success(value)
    const n = parseFloat(value)
    return isNaN(n) ? D.failure(value, "NumberFromString") : D.success(n)
  })
)

// -----------------------------------------------------------------------------
// Functions
// -----------------------------------------------------------------------------

const fileSizeUnits = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

/**
 * Format number of bytes to human-readable string.
 */
export function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) return "0 B"

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return (
    parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + fileSizeUnits[i]
  )
}
