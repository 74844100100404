import { MessageDescriptor } from "@lingui/core"
import { defineMessage } from "@lingui/macro"
import { HTTPError } from "ky"

export class NotFoundError extends Error {}

/**
 * Map error to user-friendly string that can be displayed in UI.
 *
 * Return only messages that are relevant to users (like bad internet connection,
 * wrong credentials, insufficient privileges etc.). Avoid returning messages
 * that contain unneccessary technical details. Such details should be logged
 * to external service (ie. sentry) and not displayed to users.
 */
export function displayError(error: unknown): MessageDescriptor {
  if (error instanceof HTTPError) {
    return (
      {
        [401]: defineMessage({
          id: "errors.unauthenticated",
          message: "K této akci musíte být přihlášeni",
        }),
        [403]: defineMessage({
          id: "errors.unauthorized",
          message: "K této akci nemáte dostatečná oprávnění",
        }),
        [404]: defineMessage({
          id: "errors.notFound",
          message: "Nenalezeno",
        }),
        [500]: defineMessage({
          id: "error.serverError",
          message: "Něco se pokazilo na serveru",
        }),
      }[error.response.status] ??
      defineMessage({
        id: "error.genericError",
        message: "Něco se pokazilo",
      })
    )
  }

  if (error instanceof NotFoundError) {
    return defineMessage({
      id: "errors.notFound",
      message: "Nenalezeno",
    })
  }

  // Throwed value can be anything, so we have to supply fallback message.
  return defineMessage({
    id: "error.genericError",
    message: "Něco se pokazilo",
  })
}
