import { MessageDescriptor } from "@lingui/core"
import { createDomain, forward } from "effector"
import { ReactNode } from "react"

import { displayError } from "@/lib/error"

// -----------------------------------------------------------------------------
// Model
// -----------------------------------------------------------------------------

type Severity = "danger" | "success" | "info"

export type ToastOptions = {
  message: string | MessageDescriptor
  severity?: Severity
  icon?: ReactNode
}

export type Toast = ToastOptions & {
  timestamp: Date
}

// -----------------------------------------------------------------------------
// Domain
// -----------------------------------------------------------------------------

const domain = createDomain("toasts")

// -----------------------------------------------------------------------------
// Events
// -----------------------------------------------------------------------------

export const setOpen = domain.createEvent<boolean>("setOpen")
export const openToast = domain.createEvent<ToastOptions>("openToast")
export const closeToast = domain.createEvent<void>("closed")
export const openErrorToast = domain.createEvent<unknown>("error")

forward({
  from: openErrorToast.map<ToastOptions>((error) => ({
    message: displayError(error),
    severity: "danger",
  })),
  to: openToast,
})

export const $isOpen = domain
  .createStore<boolean>(false, { name: "$isOpen" })
  .on(setOpen, (_, value) => value)
  .on(openToast, () => true)
  .on(closeToast, () => false)

export const $toast = domain
  .createStore<Toast | null>(null, {
    name: "$toast",
  })
  .on(openToast, (_, options) => ({
    ...options,
    timestamp: new Date(),
  }))
