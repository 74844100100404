const systemFont = `-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`

export const fonts = {
  body: `Nunito Sans, ${systemFont}`,
  code: 'ui-monospace, SFMono-Regular, Consolas, "Liberation Mono", Menlo, monospace',
}

export const fontSizes = {
  xs: "0.75rem",
  sm: "0.875rem",
  md: "1rem",
  lg: "1.125rem",
  xl: "1.25rem",
  "2xl": "1.5rem",
  "3xl": "1.875rem",
  "4xl": "2.25rem",
  "5xl": "3rem",
  "6xl": "3.75rem",
  "7xl": "4.5rem",
  "8xl": "6rem",
  "9xl": "8rem",
}

export const fontWeights = {
  normal: 400,
  medium: 600,
  bold: 700,
  black: 800,
}

export const letterSpacings = {
  normal: "0",
  uppercase: "0.075em",
}

// We calcualte line height as a sum of constant (rem) and proportional (em) component,
// so the ratio of line height to font size progressively diminishes with increasing font size.

export const lineHeights = {
  // sum should make 1.75, so at 16px (1rem) font size we get 28px (1.75) line height
  normal: "calc(1.25em + 0.5rem)",
}
