import { VariantProps } from "@stitches/react"
import { ComponentProps, forwardRef } from "react"
import { ReactNode } from "react"

import { styled } from "@/stitches"

import { Line } from "./line"
import { pulse } from "./skeleton"

export const Text = styled("span", {
  color: "var(--text-color)",

  svg: {
    display: "inline",
  },

  variants: {
    variant: {
      label: {
        fontSize: "$sm",
        fontWeight: "$medium",
        color: "var(--text-color, $colors$gray11)",
      },
      uppercase: {
        textTransform: "uppercase",
        letterSpacing: "$uppercase",
        fontSize: "$sm",
      },
      "uppercase-sm": {
        textTransform: "uppercase",
        letterSpacing: "$uppercase",
        fontSize: "$xs",
        fontWeight: "$bold",
      },
      "uppercase-md": {
        textTransform: "uppercase",
        letterSpacing: "$uppercase",
        fontSize: "$sm",
        fontWeight: "$medium",
      },
    },
    size: {
      xs: { fontSize: "$xs" },
      sm: { fontSize: "$sm" },
      md: { fontSize: "$md" },
      lg: { fontSize: "$lg" },
      xl: { fontSize: "$xl" },
      "2xl": { fontSize: "$2xl" },
      "3xl": { fontSize: "$3xl" },
      "4xl": { fontSize: "$4xl" },
      "5xl": { fontSize: "$5xl" },
      "6xl": { fontSize: "$6xl" },
      "7xl": { fontSize: "$7xl" },
      "8xl": { fontSize: "$8xl" },
      "9xl": { fontSize: "$9xl" },
    },
    weight: {
      normal: { fontWeight: "$normal" },
      medium: { fontWeight: "$medium" },
      bold: { fontWeight: "$bold" },
      black: { fontWeight: "$black" },
    },
    lineHeight: {
      normal: { lineHeight: "$normal" },
      inherit: {},
    },
    tracking: {
      loosest: { letterSpacing: "0.25em" },
      looser: { letterSpacing: "0.125em" },
      loose: { letterSpacing: "0.075em" },
    },
    color: {
      accent: {
        "--text-color": "$colors$accent9",
        "--text-color-hover": "$colors$accent12",
        "--underline-color": "$colors$accentA6",
      },
      primary: {
        "--text-color": "$colors$gray12",
        "--text-color-hover": "$colors$gray11",
        "--underline-color": "$colors$gray8",
      },
      muted: {
        "--text-color": "$colors$gray11",
        "--text-color-hover": "$colors$gray12",
        "--underline-color": "$colors$gray7",
      },
      danger: {
        "--text-color": "$colors$danger11",
        "--text-color-hover": "$colors$danger11",
        "--underline-color": "$colors$danger8",
      },
      success: {
        "--text-color": "$colors$success11",
        "--text-color-hover": "$colors$success11",
        "--underline-color": "$colors$success8",
      },
    },
    textAlign: {
      center: { textAlign: "center" },
    },
    blurred: {
      true: {
        color: "transparent",
        textShadow: "0 0 0.4em var(--text-color)",
      },
      false: {},
    },
    link: {
      true: {
        transition: "color 120ms, border-color 120ms",
        "&:not(:disabled):hover": {
          color: "var(--text-color-hover)",
        },
      },
      group: {
        transition: "color 120ms",
        ".group:hover &": {
          color: "var(--text-color-hover)",
        },
      },
      false: {},
    },
    button: {
      true: {
        display: "inline-flex",
        alignItems: "center",
        gap: "0.25em",
      },
      false: {},
    },
    underline: {
      true: {
        textDecoration: "underline",
        textDecorationColor: "var(--underline-color)",
        textUnderlineOffset: "0.25em",
      },
      false: {},
    },
    nowrap: {
      true: { whiteSpace: "nowrap" },
    },
    loading: {
      true: {
        borderRadius: 4,
        bgColor: "$gray6",
        "@animation": {
          animation: `${pulse} 2s ease-in-out infinite`,
        },
        "&::before": {
          content: "Načítám...",
          opacity: 0,
        },
      },
    },
    arrow: {
      true: {
        "&::after": {
          display: "inline-block",
          textDecoration: "none",
          content: "→",
          ml: "$1",
        },
      },
    },
  },
  defaultVariants: {
    lineHeight: "normal",
  },
})

export type TextProps = VariantProps<typeof Text>

export type TextButtonProps = Pick<
  ComponentProps<typeof Text>,
  "color" | "size" | "children" | "css"
> &
  Pick<ComponentProps<"button">, "type" | "onClick" | "disabled"> & {
    as: "button" | "a"
    addonBefore?: ReactNode
    addonAfter?: ReactNode
  }

export const TextButton = forwardRef<HTMLElement, TextButtonProps>(
  function TextButtonFwd(
    { as, addonBefore, addonAfter, children, ...props },
    ref
  ) {
    return (
      <Text
        ref={ref as any} // eslint-disable-line @typescript-eslint/no-explicit-any
        as={as}
        className="group"
        link
        {...props}
        css={{
          display: "inline-flex",
          alignItems: "center",
          gap: "$1",
          ...props.css,
        }}
      >
        {addonBefore}
        <Text underline link="group">
          {children}
        </Text>
        {addonAfter}
      </Text>
    )
  }
)

const TitleStyled = styled(Text, {
  fontWeight: "$bold",

  "& svg": {
    display: "inline",
    mr: "$3",
  },
})

export type TitleProps = ComponentProps<typeof TitleStyled> & {
  /* eslint-disable-next-line */
  as?: any
  children: ReactNode
}

export function Title({ children, as, ...props }: TitleProps) {
  return (
    <TitleStyled color="accent" size="xl" weight="bold" as={as} {...props}>
      <Line />
      {children}
    </TitleStyled>
  )
}
