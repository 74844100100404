import { globalCss } from "."

// CSS reset inspired by Tailwind's preflight:
// https://unpkg.com/tailwindcss@3.0.23/src/css/preflight.css

export const globalStyles = globalCss({
  "*, ::before, ::after": {
    boxSizing: "border-box",
    borderWidth: 0,
    borderStyle: "solid",
    borderColor: "currentColor",
  },
  html: {
    MozTabSize: "4",
    tabSize: 4,
    lineHeight: 1.5,
    WebkitTextSizeAdjust: "100%",
    fontFamily: "$body",
    fontSize: "calc(0.6em + 1vw)",
    "--menu-height": "5rem",
    "--viewport-width": "100vw",
    "--left-column-width": "16rem",

    "@media (min-width: 40em)": {
      fontSize: "1em",
    },

    "@3xl": {
      "--viewport-width": "calc(100vw - var(--left-column-width))",
    },

    "@menu2": {
      "--menu-height": "7rem",
    },
  },
  body: {
    margin: 0,
    color: "$gray12",
    bgColor: "$gray1",
    fontWeight: "$normal",
    fontFamily: "$body",
    lineHeight: "inherit",
  },
  hr: {
    height: 0,
    color: "inherit",
    borderTopWidth: 1,
  },
  "abbr[title]": {
    WebkitTextDecoration: "underline dotted",
    textDecoration: "underline dotted",
  },
  "b, strong": {
    fontWeight: "bolder",
  },
  "code, kbd, samp, pre": {
    fontFamily: "$code",
    fontSize: "1em",
  },
  small: {
    fontSize: "inherit",
  },
  "sub, sup": {
    fontSize: "75%",
    lineHeight: 0,
    position: "relative",
    verticalAlign: "baseline",
  },
  sub: {
    bottom: "-0.25em",
  },
  sup: {
    top: "-0.5em",
  },
  table: {
    textIndent: 0,
    borderColor: "inherit",
    borderCollapse: "collapse",
  },
  "button, input, optgroup, select, textarea": {
    fontFamily: "inherit",
    fontSize: "100%",
    lineHeight: "inherit",
    margin: 0,
    padding: 0,
    color: "inherit",
    textAlign: "inherit",
  },
  "button, select": {
    textTransform: "none",
  },
  'button, [type="button"], [type="reset"], [type="submit"]': {
    WebkitAppearance: "button",
  },
  "::-moz-focus-inner": {
    borderStyle: "none",
    padding: 0,
  },
  ":-moz-focusring": {
    outline: "auto",
  },
  ":-moz-ui-invalid": {
    boxShadow: "none",
  },
  legend: {
    padding: 0,
  },
  progress: {
    verticalAlign: "baseline",
  },
  "::-webkit-inner-spin-button, ::-webkit-outer-spin-button": {
    height: "auto",
  },
  '[type="search"]': {
    WebkitAppearance: "textfield",
    outlineOffset: -2,
  },
  "::-webkit-search-decoration": {
    WebkitAppearance: "none",
  },
  "::-webkit-file-upload-button": {
    WebkitAppearance: "button",
    font: "inherit",
  },
  summary: {
    display: "list-item",
  },
  "blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre": {
    margin: 0,
  },
  button: {
    backgroundColor: "transparent",
    backgroundImage: "none",
  },
  fieldset: {
    margin: 0,
    padding: 0,
  },
  "ol, ul, menu": {
    listStyle: "none",
    margin: 0,
    padding: 0,
  },
  img: {
    borderStyle: "solid",
  },
  textarea: {
    resize: "vertical",
  },
  "input::placeholder, textarea::placeholder": {
    opacity: 1,
    color: "#9ca3af",
  },
  'button, [role="button"]': {
    cursor: "pointer",
  },
  "h1, h2, h3, h4, h5, h6": {
    fontSize: "inherit",
    fontWeight: "inherit",
  },
  a: {
    color: "inherit",
    textDecoration: "inherit",
  },
  "pre, code, kbd, samp": {
    fontFamily:
      'ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
  },
  "img, svg, video, canvas, audio, iframe, embed, object": {
    display: "block",
    verticalAlign: "middle",
  },
  "img, video": {
    maxWidth: "100%",
    height: "auto",
  },
  "[hidden]": {
    display: "none",
  },
  svg: {
    fill: "currentColor",
  },
  ".dark-theme, .hi-contrast-light-theme, .hi-contrast-dark-theme": {
    color: "$gray12",
  },
  ":focus": {
    outline: "none",
  },
  "a:focus-visible": {
    boxShadow: "$ring",
  },
  // Scrollbar styles
  "*": {
    scrollbarWidth: 10,
    scrollbarColor: "$colors$scrollbarFg $colors$scrollbarBg",
  },
  "*::-webkit-scrollbar": {
    width: 10,
    height: 10,
  },
  "*::-webkit-scrollbar-thumb": {
    background: "$scrollbarFg",
  },
  "*::-webkit-scrollbar-track": {
    background: "$scrollbarBg",
  },
})
