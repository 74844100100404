import { record } from "fp-ts"
import { pipe } from "fp-ts/function"

import { styled } from "@/stitches"
import { containerSizes } from "@/stitches/theme/size"

export const Container = styled("div", {
  flexShrink: 0,
  variants: {
    size: pipe(
      containerSizes,
      record.mapWithIndex((key) => ({ maxWidth: `$${key}` }))
    ),
    padding: {
      default: { px: "$6" },
      none: {},
    },
    align: {
      center: {
        mx: "auto",
      },
      left: {},
    },
  },
  defaultVariants: {
    size: "2xl",
    padding: "default",
    align: "center",
  },
})
