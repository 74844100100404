import { i18n } from "@lingui/core"
import { defineMessage, plural } from "@lingui/macro"

import { formatBytes } from "@/lib/number"

export const required = defineMessage({
  id: "validation.required",
  message: `Toto pole je povinné`,
})

export const fileRequired = defineMessage({
  id: "validation.fileRequired",
  message: `Nahrajte soubor`,
})

export const mustBeInteger = defineMessage({
  id: "validation.mustBeInteger",
  message: `Vyplňte celé číslo`,
})

export const mustBeNumber = defineMessage({
  id: "validation.mustBeNumber",
  message: `Vyplňte číslo`,
})

export const invalidValue = defineMessage({
  id: "validation.invalidValue",
  message: `Neplatná hodnota`,
})

export const mustBeChecked = defineMessage({
  id: "validation.mustBeChecked",
  message: `Toto pole musí být zaškrtnuté`,
})

export const invalidEmail = defineMessage({
  id: "validation.invalidEmail",
  message: `Neplatný e-mail`,
})

export const invalidPhone = defineMessage({
  id: "validaiton.invalidPhone",
  message: `Neplatný formát telefonního čísla`,
})

export const mustBeNonEmpty = defineMessage({
  id: "validation.mustBeNonEmpty",
  message: `Pole nesmí být prázdné`,
})

export const numberOfCharacters = (n: number) =>
  defineMessage({
    id: "validation.numberOfCharacters",
    message: plural(n, {
      one: `# znak`,
      few: `# znaky`,
      many: `# znaku`,
      other: `# znaků`,
    }),
  })

export const stringTooShort = (minimum: number, inclusive: boolean) => {
  const characterCount = i18n._(numberOfCharacters(minimum))
  return inclusive
    ? defineMessage({
        id: "validation.string.lengthMustBeGreaterThan",
        message: `Text musí mít alespoň ${characterCount}`,
      })
    : defineMessage({
        id: "validation.string.lengthMustBeGreaterThanOrEqual",
        message: `Text musí mít více než ${characterCount}`,
      })
}

export const stringTooLong = (maximum: number, inclusive: boolean) => {
  const characterCount = i18n._(numberOfCharacters(maximum))
  return inclusive
    ? defineMessage({
        id: "validation.string.lengthMustBeLessThan",
        message: `Text musí mít maximálně ${characterCount}`,
      })
    : defineMessage({
        id: "validation.string.lengthMustBeLessThanOrEqual",
        message: `Text musí méně než ${characterCount}`,
      })
}

export const numberTooSmall = (minimum: number, inclusive: boolean) =>
  inclusive
    ? defineMessage({
        id: "validation.number.mustBeGreaterThan",
        message: `Číslo musí být větší nebo rovno než ${minimum}`,
      })
    : defineMessage({
        id: "validation.number.mustBeGreaterThanOrEqual",
        message: `Číslo musí být větší než ${minimum}`,
      })

export const numberTooBig = (maximum: number, inclusive: boolean) =>
  inclusive
    ? defineMessage({
        id: "validation.number.mustBeLessThan",
        message: `Číslo musí být menší nebo rovno než ${maximum}`,
      })
    : defineMessage({
        id: "validation.number.mustBeLessThanOrEqual",
        message: `Číslo musí být menší než ${maximum}`,
      })

export const fileTooBig = (maximum: number) =>
  defineMessage({
    id: "validation.file.tooBig",
    message: `Maximální velikost souboru je ${formatBytes(maximum)}`,
  })
