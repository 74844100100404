import { ord, ordering } from "fp-ts"
import { Predicate, not } from "fp-ts/lib/Predicate"

/**
 * Compares strings according to their position in localized alphabet.
 */
export const LocaleOrd = ord.fromCompare<string>((a, b) =>
  ordering.sign(a.localeCompare(b))
)

export const isLowerCase: Predicate<string> = (s) => s === s.toLowerCase()
export const isUpperCase: Predicate<string> = (s) => s === s.toUpperCase()

export const hasLowerCase = not(isUpperCase)
export const hasUpperCase = not(isLowerCase)

export const hasAtLeastOneDigit: Predicate<string> = (s) => /\d/.test(s)

export const random = (): string =>
  (Math.random() + 1).toString(36).substring(7)

/**
 * Generate slug from string.
 *
 * Source: https://gist.github.com/codeguy/6684588?permalink_comment_id=3243980#gistcomment-3243980
 */
export const toSlug = (text: string): string =>
  text
    .toString() // Cast to string (optional)
    .normalize("NFKD") // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
    .toLowerCase() // Convert the string to lowercase letters
    .trim() // Remove whitespace from both sides of a string (optional)
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/[^\w\-]+/g, "") // Remove all non-word chars
    .replace(/\-\-+/g, "-") // Replace multiple - with single -
