import { MessageDescriptor } from "@lingui/core"
import { defineMessage } from "@lingui/macro"
import * as D from "io-ts/Decoder"
import { RemixiconReactIconComponentType } from "remixicon-react"
import ComputerIcon from "remixicon-react/ComputerLineIcon"
import MoonIcon from "remixicon-react/MoonLineIcon"
import SunIcon from "remixicon-react/SunLineIcon"

// -----------------------------------------------------------------------------
// Model
// -----------------------------------------------------------------------------

export const ColorScheme = D.union(
  D.literal("light"),
  D.literal("dark"),
  D.literal("system")
)

export type ActualColorScheme = "light" | "dark"

export type ColorScheme = ActualColorScheme | "system"

export const defaultColorScheme: ColorScheme = "system"

// -----------------------------------------------------------------------------
// Options
// -----------------------------------------------------------------------------

type ColorSchemeOption = {
  value: ColorScheme
  display: MessageDescriptor
  icon: RemixiconReactIconComponentType
}

export const colorSchemeOptions: Array<ColorSchemeOption> = [
  {
    value: "light",
    display: defineMessage({ id: "colorScheme.light", message: "Světlý" }),
    icon: SunIcon,
  },
  {
    value: "dark",
    display: defineMessage({ id: "colorScheme.dark", message: "Tmavý" }),
    icon: MoonIcon,
  },
  {
    value: "system",
    display: defineMessage({
      id: "colorScheme.system",
      message: "Podle systému",
    }),
    icon: ComputerIcon,
  },
]
