import { pipe } from "fp-ts/function"
import * as D from "io-ts/Decoder"
import { useRouter } from "next/router"
import Script from "next/script"
import { useEffect } from "react"

// -----------------------------------------------------------------------------
// Google Tag Managager utilities
// -----------------------------------------------------------------------------

// Reference: https://github.com/vercel/next.js/blob/canary/examples/with-google-tag-manager

type GtmId = `GTM-${string}`

const isGtmId = (str: string): str is GtmId => /^GTM/.test(str)

export const GtmId = pipe(D.string, D.refine(isGtmId, "GtmId"))

export function trackPageView(url: GtmId) {
  window.dataLayer.push({
    event: "pageview",
    page: url,
  })
}

export function usePageView(id?: GtmId) {
  const router = useRouter()

  useEffect(() => {
    if (id) {
      router.events.on("routeChangeComplete", trackPageView)

      return () => {
        router.events.off("routeChangeComplete", trackPageView)
      }
    }
  }, [router.events])
}

export function GtmScript(props: { id: GtmId }) {
  return (
    <Script id="gtm" strategy="afterInteractive">{`
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${props.id}');
function gtag() { window.dataLayer.push(arguments); }
gtag('consent', 'default', {
  ad_storage: 'denied',
  analytics_storage: 'denied',
  functionality_storage: 'granted',
  wait_for_update: 500,
});
`}</Script>
  )
}

export function GtmNoscript(props: { id: GtmId }) {
  return (
    <noscript>
      <iframe
        src={`https://www.googletagmanager.com/ns.html?id=${props.id}`}
        height="0"
        width="0"
        style={{ display: "none", visibility: "hidden" }}
      />
    </noscript>
  )
}
