import * as D from "io-ts/Decoder"

import { ColorScheme, defaultColorScheme } from "@/domains/color-scheme/model"
import { CurrencyCode, defaultCurrencyCode } from "@/domains/currencies/model"
import { LengthUnit, defaultLengthUnit } from "@/domains/units/model"

export const WebsiteSettings = D.struct({
  currency: CurrencyCode,
  lengthUnit: LengthUnit,
  colorScheme: ColorScheme,
})

export type WebsiteSettings = D.TypeOf<typeof WebsiteSettings>

export const defaultWebsiteSettings: WebsiteSettings = {
  currency: defaultCurrencyCode,
  lengthUnit: defaultLengthUnit,
  colorScheme: defaultColorScheme,
}
