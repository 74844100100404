import { Trans } from "@lingui/macro"
import * as DialogPrimitive from "@radix-ui/react-dialog"
import { either } from "fp-ts"
import { useEffect, useRef, useState } from "react"
import { z } from "zod"

import { useForm } from "@/lib/form"
import { styled } from "@/stitches"
import { Button } from "@/ui/button"
import { LabelledCheckbox } from "@/ui/checkbox"
import { Container } from "@/ui/container"
import * as Dialog from "@/ui/dialog"
import { Stack } from "@/ui/stack"
import { Text } from "@/ui/text"

import {
  CookieConsentOptions,
  CookieConsentStorage,
  updateGtmConsentMode,
} from "./lib"

const OptionsList = styled("ul", {
  display: "grid",
  rowGap: "$4",
  columnGap: "$8",

  "@sm": {
    gridTemplateColumns: "repeat(3, 1fr)",
  },

  "@xl": {
    gridTemplateColumns: "repeat(4, 1fr)",
  },
})

const CookiesFormSchema = z.object({
  analytics: z.boolean(),
  marketing: z.boolean(),
})

export function CookieConsent() {
  const [open, setOpen] = useState(false)
  const firstFocusableCheckbox = useRef<HTMLInputElement | null>(null)
  const { register, handleSubmit } = useForm(CookiesFormSchema)

  useEffect(() => {
    const cookieConsentResult = CookieConsentStorage.get()

    if (either.isRight(cookieConsentResult)) {
      updateGtmConsentMode(cookieConsentResult.right)
    } else {
      setOpen(true)
    }
  }, [])

  const submit = (options: CookieConsentOptions) => {
    CookieConsentStorage.set(options)
    updateGtmConsentMode(options)
    setOpen(false)
  }

  const onGrantSelected = handleSubmit((data) => {
    submit({
      analytics_storage: data.analytics ? "granted" : "denied",
      ad_storage: data.marketing ? "granted" : "denied",
    })
  })

  const onGrantAll = () => {
    submit({
      analytics_storage: "granted",
      ad_storage: "granted",
    })
  }

  const preventDefault = (event: Event) => {
    event.preventDefault()
  }

  return (
    <DialogPrimitive.Root open={open} onOpenChange={setOpen}>
      <DialogPrimitive.Portal>
        <Dialog.Overlay css={{ pointerEvents: "none" }} />
        <Dialog.ContentBase
          // Prevent dialog from closing
          onEscapeKeyDown={preventDefault}
          onInteractOutside={preventDefault}
          onOpenAutoFocus={(event) => {
            event.preventDefault()
            firstFocusableCheckbox.current?.focus()
          }}
          css={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            py: "$8",
          }}
        >
          <Container as="article">
            <Container size="lg" align="left" padding="none">
              <DialogPrimitive.Title asChild>
                <Text as="h1" size="xl" weight="bold" css={{ mb: "$2" }}>
                  <Trans id="cookieConsent.header">Používáme cookies</Trans>
                </Text>
              </DialogPrimitive.Title>
              <DialogPrimitive.Description>
                <Text color="muted" weight="medium" size="sm">
                  <Trans id="cookieConsent.description">
                    Soubory cookies používáme k analýze údajů o našich
                    návštěvnících, ke zlepšení našich webových stránek,
                    zobrazení personalizovaného obsahu a k tomu, abychom vám
                    poskytli co nejlepší zážitek z návštěvy webu. Více se o
                    používaných cookies můžete dozvědět v{" "}
                    <Text
                      as="a"
                      color="accent"
                      link
                      underline
                      href="/podminky-uziti-weboveho-rozhrani"
                      target="_blank"
                    >
                      podmínkách užití webového rozhraní
                    </Text>
                    .
                  </Trans>
                </Text>
              </DialogPrimitive.Description>
            </Container>
            <Stack
              css={{ mt: "$6" }}
              as="form"
              gap={9}
              direction={{ "@initial": "column", "@md": "row" }}
              align={{ "@md": "center" }}
              justify="between"
              onSubmit={onGrantSelected}
            >
              <OptionsList>
                <li>
                  <LabelledCheckbox
                    disabled
                    checked
                    hint={
                      <Trans id="cookieConsent.options.necessary.description">
                        Tyto soubory cookies jsou nezbytné pro fungování
                        webových stránek a není tedy možné je zakázat.
                      </Trans>
                    }
                  >
                    <Trans id="cookieConsent.options.necessary.label">
                      Funkční
                    </Trans>
                  </LabelledCheckbox>
                </li>
                <li>
                  <LabelledCheckbox
                    {...register("analytics")}
                    hint={
                      <Trans id="cookieConsent.options.analytics.description">
                        Tyto soubory cookies se používají ke zlepšení fungování
                        webových stránek. Umožňují nám rozpoznat a sledovat
                        počet návštěvníků a sledovat, jak návštěvníci web
                        používají. Pomáhají nám zlepšovat způsob, jakým webové
                        stránky fungují, například tím, že uživatelům umožňují
                        snadno najít to, co hledají. Tyto soubory cookie
                        neshromažďují informace, které by vás mohly
                        identifikovat. Pomocí těchto nástrojů analyzujeme a
                        pravidelně zlepšujeme funkčnost našich webových stránek.
                        Získané statistiky můžeme využít ke zlepšení
                        uživatelského komfortu a k tomu, aby byla návštěva webu
                        pro vás jako uživatele zajímavější.
                      </Trans>
                    }
                  >
                    <Trans id="cookieConsent.options.analytics.label">
                      Analytické
                    </Trans>
                  </LabelledCheckbox>
                </li>
                <li>
                  <LabelledCheckbox
                    {...register("marketing")}
                    hint={
                      <Trans id="cookieConsent.options.marketing.description">
                        Používají se ke sledování preferencí webu uživatele za
                        účelem cílení reklamy, tj. zobrazování marketingových a
                        reklamních sdělení (i na stránkách třetích stran), které
                        mohou návštěvníka webu zajímat, v souladu s těmito
                        preferencemi. Marketingové cookies využívají nástroje
                        externích společností. Tyto marketingové soubory cookie
                        budou použity pouze s vaším souhlasem.
                      </Trans>
                    }
                  >
                    <Trans id="cookieConsent.options.marketing.label">
                      Marketingové
                    </Trans>
                  </LabelledCheckbox>
                </li>
              </OptionsList>
              <Stack gap={4} css={{ alignSelf: "end" }}>
                <Button type="submit" variant="ghost" color="secondary">
                  <Trans id="cookieConsent.grantSelected">
                    Povolit vybrané
                  </Trans>
                </Button>
                <Button type="button" onClick={onGrantAll}>
                  <Trans id="cookieConsent.grantAll">Povolit všechny</Trans>
                </Button>
              </Stack>
            </Stack>
          </Container>
        </Dialog.ContentBase>
      </DialogPrimitive.Portal>
    </DialogPrimitive.Root>
  )
}
