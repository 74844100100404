import * as D from "io-ts/Decoder"

import { createStorageItem } from "@/lib/storage"

const Access = D.union(
  D.literal("granted"), //
  D.literal("denied")
)

const CookieConsentOptions = D.partial({
  ad_storage: Access,
  analytics_storage: Access,
})

/**
 * See available [consent types](https://developers.google.com/tag-platform/devguides/privacy#consent_mode_terminology)
 */
export type CookieConsentOptions = D.TypeOf<typeof CookieConsentOptions>

export const CookieConsentStorage = createStorageItem({
  key: "cookieConsent",
  decoder: CookieConsentOptions,
})

export function updateGtmConsentMode(options: CookieConsentOptions) {
  if ("gtag" in window) {
    window.gtag("consent", "update", options)
  }
}
