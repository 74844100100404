import * as TooltipPrimitive from "@radix-ui/react-tooltip"
import { ReactNode } from "react"

import { keyframes, styled } from "@/stitches"

// -----------------------------------------------------------------------------
// Primitives
// -----------------------------------------------------------------------------

const slideUpAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateY(2px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
})

const slideRightAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateX(-2px)" },
  "100%": { opacity: 1, transform: "translateX(0)" },
})

const slideDownAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateY(-2px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
})

const slideLeftAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateX(2px)" },
  "100%": { opacity: 1, transform: "translateX(0)" },
})

const ContentStyled = styled(TooltipPrimitive.Content, {
  padding: "$4 $6",
  fontSize: 15,
  color: "black",
  backgroundColor: "white",
  boxShadow: "$shadow1",
  zIndex: "$tooltip",
  maxWidth: "30rem",
  "@animation": {
    animationDuration: "400ms",
    animationTimingFunction: "cubic-bezier(0.16, 1, 0.3, 1)",
    willChange: "transform, opacity",
    '&[data-state="delayed-open"]': {
      '&[data-side="top"]': { animationName: slideDownAndFade },
      '&[data-side="right"]': { animationName: slideLeftAndFade },
      '&[data-side="bottom"]': { animationName: slideUpAndFade },
      '&[data-side="left"]': { animationName: slideRightAndFade },
    },
  },
})

const Arrow = styled(TooltipPrimitive.Arrow, {
  fill: "white",
  width: "1rem",
  height: "0.5rem",
})

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------

type TooltipProps = TooltipPrimitive.TooltipProps &
  Pick<TooltipPrimitive.TooltipTriggerProps, "asChild"> & {
    content?: ReactNode
    disabled?: boolean
    wrap?: boolean
  }

export function Tooltip({
  children,
  content,
  asChild,
  disabled = false,
  wrap = false,
  ...props
}: TooltipProps) {
  if (disabled || !content) {
    return <>{children}</>
  }

  return (
    <TooltipPrimitive.Provider delayDuration={200}>
      <TooltipPrimitive.Root {...props}>
        <TooltipPrimitive.Trigger {...{ asChild }}>
          {wrap ? <span tabIndex={0}>{children}</span> : children}
        </TooltipPrimitive.Trigger>
        <TooltipPrimitive.Portal>
          <ContentStyled {...props}>
            {content}
            <Arrow />
          </ContentStyled>
        </TooltipPrimitive.Portal>
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  )
}
